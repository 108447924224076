import {
  Autocomplete,
  Skeleton,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Country } from 'country-region-data'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  selectedCountryCode?: string
  onChange?: (country: Country) => Promise<void> | void
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void
  TextFieldProps?: TextFieldProps
  disabled?: boolean
}

export const CountrySelector = (props: Props) => {
  const [countryData, setCountryData] = React.useState<Country[]>([])
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation()

  React.useEffect(() => {
    import('country-region-data/data.json')
      .then((countryData) => {
        setCountryData(countryData.default as Country[])
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading || !countryData.length) {
    return <Skeleton height={'100%'} style={{ transform: 'none' }} />
  }

  return (
    <Autocomplete
      options={countryData}
      loading={loading}
      isOptionEqualToValue={(option, value) => {
        if (!Boolean(value?.countryName) || option.countryName === '')
          return false

        return option?.countryShortCode === value?.countryShortCode
      }}
      getOptionLabel={(option) =>
        typeof option === 'object' && option?.countryName
      }
      disabled={loading || props.disabled}
      // autoComplete
      autoHighlight
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      autoSelect
      disableClearable
      onChange={(e, value) => {
        if (!value || typeof value === 'string') return
        props.onChange && props.onChange(value)
      }}
      value={
        countryData.find(
          (x) => x.countryShortCode === props.selectedCountryCode
        ) || null
      }
      renderInput={(params) => (
        <TextField
          label={t('common:country')}
          {...props.TextFieldProps}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...(props.TextFieldProps?.inputProps || {}),
          }}
        />
      )}
    />
  )
}
