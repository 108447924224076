export const currencyOptions = [
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'AUD',
    value: 'AUD',
  },
  {
    label: 'CAD',
    value: 'CAD',
  },
  {
    label: 'CHF',
    value: 'CHF',
  },
  {
    label: 'BRL',
    value: 'BRL',
  },
  {
    label: 'DKK',
    value: 'DKK',
  },
  {
    label: 'JPY',
    value: 'JPY',
  },
  {
    label: 'CNY',
    value: 'CNY',
  },
  {
    label: 'EGP',
    value: 'EGP',
  },
  {
    label: 'PLN',
    value: 'PLN',
  }
]
