import { ArrowDropDownOutlined } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'

type Props = {
  workingStepType: WorkingStepType
  handleRemoveWorkingStep: (workingStepType: WorkingStepType) => void
  handleRemoveWorkingStepFromOtherRows: (
    workingStepType: WorkingStepType
  ) => void
}
export const WorkingStepRemoveButton = (props: Props) => {
  const { t } = useTranslation()
  const buttonGroupRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  return (
    <>
      <ButtonGroup
        ref={buttonGroupRef}
        variant="outlined"
        size="small"
        color="secondary"
      >
        <Button
          onClick={() => props.handleRemoveWorkingStep(props.workingStepType)}
        >
          {t('project:remove-working-step-tooltip')}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'remove-working-step-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          aria-label={t(
            'project:remove-working-step-tooltip-options',
            'select remove working step options'
          )}
          onClick={() => setOpen((o) => !o)}
        >
          <ArrowDropDownOutlined />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{ zIndex: 2000 }}
        open={open}
        anchorEl={buttonGroupRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              {/* <ClickAwayListener onClickAway={() => setOpen(false)}> */}
              <MenuList id="remove-working-step-options">
                <MenuItem
                  onClick={() => {
                    props.handleRemoveWorkingStep(props.workingStepType)
                  }}
                >
                  <Typography variant="body2">
                    {t('project:remove-working-step-tooltip')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    props.handleRemoveWorkingStepFromOtherRows(
                      props.workingStepType
                    )
                  }
                >
                  <Typography variant="body2">
                    {t('project:remove-working-step-tooltip-from-other-rows')}
                  </Typography>
                </MenuItem>
              </MenuList>
              {/* </ClickAwayListener> */}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
