import { ProjectsSummariesController } from 'controllers/Projects/ProjectsSummariesController'
import { useAppController } from 'customHooks/useAppController'
import { AppEventsController } from 'features/AppEventTrack/appEventsController'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import { noop, throttle } from 'lodash'
import { startTransition, useCallback, useRef, useState } from 'react'
import { HttpGetMetaData } from 'services/APIs/InternalAPI/InternalAPI'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useOrganizationContext } from 'utils/useOrganizationContext'
import { useClientStorage } from '../../customHooks/useClientStorage'

const getGroupName = (partyId: string) => `${partyId}_project_list`

export const useProjectList = (isFirstRender: boolean) => {
  const [onlyMyProjects, setOnlyMyProjects] = useClientStorage<boolean>(
    'filterMyProjects',
    false
  )
  const [showCanceledProjects, setShowCanceledProjects] =
    useClientStorage<boolean>('showCanceledProjects', false)

  const [pageSize, setPageSize] = useClientStorage<number>(
    'projectListPageSize',
    20
  )

  const [projectList, setProjectList] = useState<ProjectSummaryDto[]>(undefined)
  const [projectStates, setProjectStates] = useState<string[]>(undefined)

  const { controller, loading } = useAppController(
    () => new ProjectsSummariesController()
  )

  const firstRender = useRef(isFirstRender)

  const { controller: appEventsController } = useAppController(
    () => new AppEventsController()
  )

  const trackProjectListOpened = useCallback(
    async (success: boolean) => {
      try {
        appEventsController.PostEvent({
          eventType: 'ProjectsListed',
          eventData: {
            success,
          },
        })
      } catch (err) {
        console.error('failed to track project list opened', err)
      }
    },
    [appEventsController]
  )

  const fetchProjectListPaged = useCallback(
    async (meta?: HttpGetMetaData<Partial<ProjectSummaryDto>>) => {
      try {
        const projectList = await controller.GetProjectsListPaged(
          onlyMyProjects,
          showCanceledProjects,
          meta
        )

        if (firstRender.current) {
          trackProjectListOpened(true)
          firstRender.current = false
        }

        return projectList
      } catch (err) {
        ShowException('project list', err)

        if (firstRender.current) {
          trackProjectListOpened(false)
        }

        return undefined
      }
    },
    [controller, onlyMyProjects, showCanceledProjects, trackProjectListOpened]
  )

  const updateProjectListPaged = useCallback(
    async (meta?: HttpGetMetaData<Partial<ProjectSummaryDto>>) => {
      const pagedResponse = await fetchProjectListPaged(meta)

      startTransition(() => {
        setProjectList(pagedResponse?.details)
        setProjectStates(pagedResponse?.projectStates)
      })
    },
    [fetchProjectListPaged]
  )

  const updateProjectStatus = async (
    projectId: string,
    initialStatus: string,
    targetStatus: string,
    position: number
  ) => {
    setProjectList((current) => {
      return current
        .map((x) =>
          x.id === projectId
            ? {
                ...x,
                status: targetStatus,
                lastOperation: new Date(),
              }
            : x
        )
        .sort(
          (a, b) =>
            new Date(b.lastOperation).getTime() -
            new Date(a.lastOperation).getTime()
        )
    })

    await controller.UpdateProjectStatus(
      projectId,
      initialStatus,
      targetStatus,
      position
    )
  }

  const handleSetPriority = useCallback(
    async (projectId: string, priority: number) => {
      setProjectList((current) => {
        return current
          .map((x) =>
            x.id === projectId
              ? {
                  ...x,
                  priority: priority,
                  lastOperation: new Date(),
                }
              : x
          )
          .sort(
            (a, b) =>
              new Date(b.lastOperation).getTime() -
              new Date(a.lastOperation).getTime()
          )
      })

      await controller.UpdateProjectPriority(projectId, priority)
    },
    [controller]
  )

  const { partyId: partyId } = useOrganizationContext()
  const [isConnected, setIsConnected] = useState(false)

  const connectToUpdates = useCallback(
    (callback: () => void) => {
      const debouncedCallback = throttle(() => {
        callback()
      }, 3000)

      const onServerUpdate = () => debouncedCallback()

      const connectToSignalR = async () => {
        const hub = await SignalRService.GetHub()
        hub.registerHandler('onProjectsListUpdates', onServerUpdate)
        hub.JoinGroup(getGroupName(partyId), () => {
          setIsConnected(true)
        })
      }

      connectToSignalR()
    },
    [partyId]
  )

  const disconnectFromUpdates = useCallback(() => {
    SignalRService.GetHub().then((hub) => {
      hub.unregister('onProjectsListUpdates')

      hub.LeaveGroup(getGroupName(partyId), () => noop(partyId))
    })
    setIsConnected(false)
  }, [partyId])

  return {
    onlyMyProjects,
    setOnlyMyProjects,
    setShowCanceledProjects,
    projectList,
    projectStates,
    updateProjectListPaged,
    fetchProjectListPaged,
    pageSize,
    setPageSize,
    loading,
    updateProjectStatus,
    connectToUpdates,
    disconnectFromUpdates,
    isConnected,
    groupName: getGroupName(partyId),
    handleSetPriority,
    showCanceledProjects,
  }
}
