/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteProps,
  InputAdornment,
  TextField,
} from '@mui/material'
import { debounce } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RequestStatus } from '../../../services/APIs/BaseAPI'
import { SearchPartyAPI } from '../../../services/APIs/InternalAPI/SearchPartyAPI'
import {
  PartyDto,
  PartySearchResult,
} from '../../../services/APIs/InternalAPI/internal-api.contracts'
import { ErrorMessage } from '../../../store/Application/appActions'
import { useOrganizationAndProjectContext } from '../../../utils/useOrganizationContext'
import { FindPartyResult } from './FindPartyResult'

type Props = {
  onPartyDetailsReceived: (buyerPartyDto: PartyDto) => void
  onPartyDetailsLoading?: (isLoading: boolean) => void
  onPartyDetailsCleaned: () => void
  searchPortalParties?: boolean
  TextFieldProps?: Partial<React.ComponentProps<typeof TextField>>
} & Partial<AutocompleteProps<PartySearchResult, false, false, false>>

const debouncedSearch = debounce(
  async (
    API: SearchPartyAPI,
    searchText,
    onRequestChange: (req: Partial<RequestStatus>) => void,
    onRequestFinish: (options) => void,
    searchPortalParties?: boolean
  ) => {
    try {
      const options = searchPortalParties
        ? (await API.SearchPortalParties(searchText, onRequestChange)).customers
        : await API.SearchParty(searchText, onRequestChange)

      onRequestFinish(options || [])
      return options
    } catch (err) {
      ErrorMessage('search parties', err['message'])
      return []
    }
  },
  1000,
  { leading: false, trailing: true }
)

export const SearchParty = (props: Props) => {
  const { t } = useTranslation()
  const {
    onPartyDetailsReceived,
    onPartyDetailsLoading,
    onPartyDetailsCleaned,
    searchPortalParties,
    TextFieldProps,
    ...autoCompleteProps
  } = props
  const [searchText, setSearchText] = useState<string>()
  const [options, setOptions] = useState<PartySearchResult[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { partyId: organizationId } = useOrganizationAndProjectContext()
  const [selectedValue, setSelectedValue] = useState<PartySearchResult | null>(
    null
  )

  const API = new SearchPartyAPI(organizationId)

  const getDetails = async (findResult: PartySearchResult) => {
    if (findResult) {
      try {
        const details = await API.GetPartyDetails(
          findResult.searchKey,
          (req) => onPartyDetailsLoading && onPartyDetailsLoading(req.isLoading)
        )

        onPartyDetailsReceived(details)
      } catch (err) {
        if (err['message'] !== 'cancelled')
          ErrorMessage('search', err['message'])
      }
    } else {
      onPartyDetailsCleaned()
    }
  }

  const handleSearchTextChanged = (e) => {
    {
      const value = e.currentTarget.value

      if (value.length < 3) {
        setOptions([])
        debouncedSearch.cancel()
        setIsLoading(false)
      }

      if (value.length >= 3) {
        if (
          value < searchText ||
          !options.filter((x) => x.names[0].toLowerCase().startsWith(value))
            .length
        ) {
          setIsLoading(true)
          debouncedSearch(
            API,
            e.currentTarget.value,
            () => true,
            (options) => {
              setIsLoading(false)
              setOptions(options)
            },
            props.searchPortalParties
          )
        }
      }

      setSearchText(value)
    }
  }

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <Autocomplete<PartySearchResult, false, false, false, 'div'>
        {...autoCompleteProps}
        autoComplete
        onChange={async (ev, value) => {
          if (typeof value === 'string') {
            return
          } else {
            await getDetails(value)
            setSelectedValue(value)
          }
        }}
        autoHighlight
        options={options}
        loading={isLoading}
        filterOptions={(options) => options} //not needed to filter the resultsP
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          } else {
            return option.names[0]
          }
        }}
        value={selectedValue}
        isOptionEqualToValue={(option, value) => {
          return (
            option.toString() === '' || option.searchKey === value.searchKey
          )
        }}
        clearOnEscape
        renderOption={(params, option, state) => {
          return (
            <FindPartyResult
              baseElProps={params}
              option={option}
              key={option.id}
              searchText={state.inputValue}
            />
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant={'standard'}
              // label={`find a company`}
              placeholder={t('common:search')}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
              {...(props.TextFieldProps ?? {})}
              InputProps={{
                ...params.InputProps,
                ...(props.TextFieldProps?.InputProps ?? {}),

                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined fontSize="small" color="action" />
                  </InputAdornment>
                ),
                // endAdornment: (
                //   <InputAdornment position="end">
                //     {params.InputProps.endAdornment}
                //     <IconButton
                //       onClick={() => {
                //         dispatch(ShowNewProjectForm(false))
                //         navigate('/app/settings/supply-chain/customers')
                //       }}
                //       title={t('common:settings')}
                //     >
                //       <SettingsOutlined />
                //     </IconButton>
                //   </InputAdornment>
                // ),
                // )
              }}
              onChange={handleSearchTextChanged}
              value={searchText}
              autoComplete="new-password"
            />
          )
        }}
      />
    </div>
  )
}
