import { FileUploadDialog } from 'components/Common/FileUploader/FileUploaderDialog'
import { useHandleDragFiles } from 'components/Common/useHandleDragFiles'
import { PortalHome } from 'features/AppHome/PortalHome'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrganizationContext } from 'utils/useOrganizationContext'

const AppPage = () => {
  const navigate = useNavigate()

  const { partyId: organizationId } = useOrganizationContext()
  const [newFileUploadActive, setNewFileUploadActive] = useState(false)
  const { handleDragFiles } = useHandleDragFiles((isDragging) =>
    setNewFileUploadActive(isDragging)
  )

  return (
    <>
      <section onDragOver={handleDragFiles}>
        <FileUploadDialog
          isActive={newFileUploadActive}
          setIsActive={setNewFileUploadActive}
          filePostUrl={`api/parties/${organizationId}/projects/import`}
          networkLabel="file-importer"
          onFileUploadSucceed={() => {
            setNewFileUploadActive(false)
          }}
          onFileUploadFail={() => setNewFileUploadActive(false)}
          allowedExtensions={{
            'application/*': ['.xml', '.pdf'],
          }}
          allowedExtensionsLabel={['.xml']}
        />
        <PortalHome />
      </section>
    </>
  )
}

export default AppPage
